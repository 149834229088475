import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const VideoPage = ({ pageContext }) => {
  return (
    <Layout>
      <SEO
        description={`A video by Spencer Wells ${pageContext.videoLink}`}
        title="Video"
      />
      <div className="h-screen w-full">
        <iframe
          className="w-full h-full p-10"
          src={`${pageContext.videoLink}?autoplay=true&title=false&byline=false&portrait=false&color=f6f4ef`}
        ></iframe>
      </div>
    </Layout>
  );
};
export default VideoPage;
